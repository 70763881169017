/* @import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC:300,400,500,700,900&subset=vietnamese'); */

html {
  font-family: Arial, Helvetica, sans-serif !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
a {
  color: #229BD4;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
a:active,
a:hover {
  outline-width: 0;
}
a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #229BD4;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease-in-out;
  transition-delay: 0.1s;
}
a:hover::after {
  transform: scaleX(1);
  transition-delay: 0s;
}
a.active::after {
  transform: scaleX(1);
}
.no-underline::after {
  background-color: transparent !important;
  height: 0px !important;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
optgroup,
select,
a,
textarea {
  font: inherit;
  margin: 0;
  outline: none;
}
optgroup {
  font-weight: 700;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html {
  box-sizing: border-box;
  overflow-y: scroll;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
body {
  color: hsla(0, 0%, 0%, 0.8);
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}
img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.7rem !important;
  line-height: 1.1;
}
h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.32671rem !important;
  line-height: 1.1;
}
h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.18316rem !important;
  line-height: 1.1;
}
h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.9rem !important;
  line-height: 1.1;
}
h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.75028rem !important;
  line-height: 1.1;
}
h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem !important;
  line-height: 1.1;
}
hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
ul {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  list-style-position: outside;
  list-style-image: none;
}
ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  list-style-position: outside;
  list-style-image: none;
}
dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem;
}
table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
  border-collapse: collapse;
  width: 100%;
}
fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}
address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dt {
  font-weight: bold;
}
th {
  font-weight: bold;
}
li {
  margin-bottom: 0;
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
li > ol {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
li > ul {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
blockquote *:last-child {
  margin-bottom: 0;
}
li *:last-child {
  margin-bottom: 0;
}
p *:last-child {
  margin-bottom: 0;
}
li > p {
  margin-bottom: 0;
}
code {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
kbd {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
samp {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}
thead {
  text-align: left;
}
td,
th {
  text-align: left;
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px);
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
tt,
code {
  background-color: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
pre code {
  background: none;
  line-height: 1.42;
}
code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: " ";
}
pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: "";
}
@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}
.slick-dots {
  bottom: 10px !important;
}
.slick-dots li.slick-active button:before {
  font-size: 10px !important;
  color: white !important;
}
.slick-dots li button:before {
  font-size: 10px !important;
  color: white !important;
}
.ql-snow .ql-picker.ql-header {
  line-height: 1.5;
  outline: none;
}
.ql-editor {
  min-height: 250px !important;
}
.ql-align-center {
  text-align: center;
}
.spacer-container {
  height: 100px;
  display: block;
  clear: both;
}
/* .ql-toolbar.ql-snow {
  position: sticky;
} */
.ql-container.ql-snow {
  max-height: 80vh;
  overflow: scroll;
}
.slick-prev:before, .slick-next:before {
  display: none;
  opacity: 0;
  content: '';
}
.image-gallery-image {
  min-height: 330px;
}
.ant-menu {
  font-family: Arial, Helvetica, sans-serif !important;
}
.ant-list-vertical .ant-list-item-content {
  margin-bottom: 0px !important;
}
.ant-list-item {
  margin-bottom: 0px !important;
}
.ql-indent-1 {
  margin-left: 20px;
  list-style: circle;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before, .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-font .ql-picker-options span::before {
  content: attr(data-value) !important;
}

.ql-picker-options span[data-value="Arial"]::before {
  font-family: "Arial";
}
.ql-picker-options span[data-value="Arial_Black"]::before {
  font-family: "Arial Black";
}
.ql-picker-options span[data-value="Comic_Sans_MS"]::before {
  font-family: "Comic Sans MS";
}
.ql-picker-options span[data-value="Courier_New"]::before {
  font-family: "Courier New";
}
.ql-picker-options span[data-value="Impact"]::before {
  font-family: "Impact";
}
.ql-picker-options span[data-value="Tahoma"]::before {
  font-family: "Tahoma";
}
.ql-picker-options span[data-value="Verdana"]::before {
  font-family: "Verdana";
}
.ql-picker-options span[data-value="Helvetica"]::before {
  font-family: "Helvetica";
}
.ql-picker-options span[data-value="Times_New_Roman"]::before {
  font-family: "Times New Roman";
}
.ql-picker-options span[data-value="Source_Sans_Pro"]::before {
  font-family: "Source Sans Pro";
}

.ql-font-Arial {
  font-family: "Arial";
}
.ql-font-Arial_Black {
  font-family: "Arial Black";
}
.ql-font-Comic_Sans_MS {
  font-family: "Comic Sans MS";
}
.ql-font-Courier_New {
  font-family: "Courier New";
}
.ql-font-Impact {
  font-family: "Impact";
}
.ql-font-Tahoma {
  font-family: "Tahoma";
}
.ql-font-Verdana {
  font-family: "Verdana";
}
.ql-font-Helvetica {
  font-family: "Helvetica";
}
.ql-font-Times_New_Roman {
  font-family: "Times New Roman";
}
.ql-font-Source_Sans_Pro {
  font-family: "Source Sans Pro";
}

.ql-editor {
  font-family: "Arial";
  display: block;
}

.ql-editHtml:after {
  content: "</>";
  font-weight: bold;
  display: -webkit-inline-box;
}

.raw-editor { display: none; width: 100% !important; }
.showRaw .ql-editor  { display: none; }
.showRaw .raw-editor { display: block; }

.ql-indent-1::before {
  content: '○' !important;
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 1.5rem !important;
  }
  h2 {
    font-size: 1.2rem !important;
  }
  h3 {
    font-size: 1rem !important;
  }
}

.zoom-modal {
  display: none;
}

@media (max-width: 30em) {
  .zoom-modal {
    display: flex;
  }
}

.ant-tabs {
  overflow: visible !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}