.ant-layout-header > ul > li.ant-menu-item > span > i {
  font-size: 18px;
  margin-right: 0px;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
/* a::after {
  display: none !important;
} */
a:hover {
  color: unset;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}
.trigger:hover {
  color: #1890ff;
}
.ant-tabs-card > .ant-tabs-bar {
  margin-bottom: 0px;
}